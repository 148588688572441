import { useRef } from "react";

import Slider from "infinite-react-carousel";

const Carousel = props => {
  const { spin, current, vidCode, allVids } = props;
  // console.log(spin, current, vidCode, allVids);

  const sliderRef = useRef(null);

  const settings = {
    arrows: false,
    initialSlide: 0,
    ref: sliderRef,
    rows: 1
  };
  if (spin !== null) {
    sliderRef.current?.slickGoTo(spin);
  } else {
    sliderRef.current?.slickGoTo(current || 0);
  }

  // console.log("settings", settings);

  return (
    <Slider {...settings}>
      {allVids.map(v => (
        <div className="sliderCell" key={v}>
          <img src={vidCode(v).thumb} alt={v} title={v} />
        </div>
      ))}
    </Slider>
  );
};

export default Carousel;
