import './App.css';

import React, { useState } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import {
  createMuiTheme,
  makeStyles,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AccountCircle from '@material-ui/icons/AccountCircle';

import Main from './Main';
import {
  Tc,
  Th6,
} from './TG';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: green[800]
    },
    secondary: {
      main: red[900]
    }
  },
  props: {
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true
    }
  }
});
theme = responsiveFontSizes(theme);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));

function App() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static">
        <Toolbar>
          <Th6 className={classes.title}>
            Whamageddon Roulette 2023!
          </Th6>
          <IconButton edge="end" onClick={handleDialogOpen} color="inherit">
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Main />{" "}
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Happy Holidays!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>
              This just a bit of fluff, mostly for me to experiment with the
              latest versions of react and cra before upgrading my main
              projects. There are no cookies, no tracking (other than my ISP
              logging), no advertisements (other than what YouTube does), and no
              gotchas other than Whamageddon itself.
            </p>
            <p>
              I'm{" "}
              <a href="https://jwsdev.net/" target="_blank" rel="noreferrer">
                Joe Shelby
              </a>
              , professional software developer since 1993, specializing in UI
              design and development.
            </p>
            <p>
              Check back every so often, as I might update the other videos with
              more of my favorites of the season...
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Tc>Version 1.1.2023</Tc>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default App;
